/* App.css */

/* Basic Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  color: #333;
}

/* Light Mode */
body {
  background-color: #f4f4f4;
  color: #333;
}

/* Container */
.app-container {
  margin: 0 auto;
  padding: 10px;
  position: relative;
  background-color: #f4f4f4;
  color: #333;
}

/* Header: Time Settings and Reset Button */
.header-container {
  display: flex;
  justify-content: space-between; /* Spread items to the sides */
  align-items: center;
  margin-bottom: 10px;
  text-align: center;
  position: relative; /* Allows positioning of other elements */
  height: 50px;
  background-color: #f4f4f4;
}

.header-container p {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: center; /* Ensure text is centered */
}

.header-container p a {
  color: #666; /* Default link color */
  text-decoration: none; /* Remove underline */
  transition: color 0.3s; /* Smooth transition for color change */
}

.header-container p a:hover {
  color: #0056b3; /* Darker color on hover */
}

.header-container .reset-button-container {
  position: absolute;
  right: 0; /* Align to the far right */
}

.header-container .settings-icon {
  cursor: pointer;
  font-size: 24px; /* Keep the cog size */
  padding: 10px; /* Increase the clickable area */
  display: flex;
  align-items: center;
  justify-content: center;
}

.time-settings {
  display: flex;
  align-items: center;
}

.time-settings label {
  font-weight: bold;
}

.time-settings input[type="number"] {
  border: 1px solid #ccc;
  border-radius: 5px;
}

button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #0056b3;
}

/* Bike Queue Container */
.bike-queue-container {
  display: flex;
  justify-content: center; /* Center the bike queue items */
  gap: 20px;
  flex-wrap: nowrap;
  overflow-x: auto; /* Make the container scrollable */
  max-height: calc(100vh - 200px); /* Adjust height to fit within the viewport */
}

.bike-queue {
  flex: 1;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  min-width: 250px; /* Ensure a minimum width for each bike queue */
  max-width: 300px; /* Ensure a maximum width for each bike queue */
  overflow-y: auto; /* Make the bike queue scrollable */
  color: #333;
}


/* Bike Queue Header */
.bike-queue-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bike-queue h2 {
  font-size: 20px;
}

.bike-queue button {
  cursor: pointer;
}

.bike-queue button .fa-trash {
  font-size: 16px;
}

.timer {
  font-size: 20px;
  font-weight: bold;
}

.timer.orange {
  color: orange;
}

.timer.green {
  color: green;
}

/* Current User Display */
.bike-queue .current-user {
  font-size: 18px;
  margin: 10px 0;
}

/* Form and Queue List */
.bike-queue input[type="text"] {
  padding: 10px;
  font-size: 16px;
  width: 100%;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
  color: #333;
}

input[type="text"],
input[type="number"],
select {
  background-color: white;
  color: #333;
  border: 1px solid #ccc;
}

.bike-queue ul {
  list-style-type: none;
  padding-left: 0;
}

.bike-queue li {
  padding: 6px 0; /* Reduced padding to improve drag behavior */
  margin-bottom: 5px; /* Added margin for spacing */
}

/* Draggable Item */
.draggable-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
  background-color: #f9f9f9;
  font-size: 18px;
  transition: transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease; /* Add transition for smooth animation */
  position: relative; /* Ensures z-index takes effect */
  z-index: 10; /* Priority for draggable item layering */
}

.draggable-item.dragging {
  background-color: #e0f7fa; /* Subtle highlight */
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000; /* Ensures it's above everything else */
  cursor: grabbing;
  margin-bottom: 0; /* Removes spacing when dragging */
  transform: scale(1.05); /* Slightly enlarge the item when dragging */
}

.draggable-item .arrow-icon,
.draggable-item .fa-trash {
  padding: 10px; /* Increase padding to make it easier to click */
  font-size: 24px; /* Increase font size for better visibility */
}

.drag-icon {
  cursor: grab;
  margin-right: 10px;
}

.arrow-icon {
  cursor: pointer;
  margin: 0 5px;
  font-size: 20px;
  color: #007bff;
  transition: color 0.3s;
}

.arrow-icon:hover {
  color: #0056b3;
}

.arrow-icon.disabled {
  color: #ccc;
  cursor: not-allowed;
}

/* Settings Modal */
.settings-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it is above other elements */
}

.settings-modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 80%; /* Adjust width for iPad */
  max-width: 600px; /* Set a max-width for iPad */
  position: relative;
  z-index: 1001; /* Ensure it is above the modal background */
  color: #333;
}

.settings-modal-content h2 {
  margin-top: 0;
  font-size: 24px; /* Ensure h2 font size is defined */
}

.settings-modal-content .fa-xmark {
  font-size: 24px; /* Match the font size of h2 */
  cursor: pointer;
  float: right;
}

.settings-modal-content label {
  display: block;
  margin-top: 10px;
}

.settings-modal-content input[type="number"],
.settings-modal-content input[type="text"],
.settings-modal-content select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 18px;
  background-color: white;
  color: #333;
}

.settings-modal-content button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
}

.settings-modal-content button:hover {
  background-color: #0056b3;
}

.settings-modal-content input[type="text"] {
  width: auto; /* Allow the input to shrink */
  flex: 1; /* Allow the input to take available space */
  margin-right: 0; /* Remove right margin */
  border-radius: 5px 0 0 5px; /* Round only the left corners */
}

.settings-modal-content button {
  margin-left: 0; /* Remove left margin */
  border-radius: 0 5px 5px 0; /* Round only the right corners */
}

/* Responsive Design */
@media (max-width: 768px) {
  .bike-queue-container {
    flex-direction: column;
    align-items: center;
    overflow-y: auto; /* Allow vertical scrolling for the container */
    max-height: none; /* Remove max-height restriction */
  }

  .bike-queue {
    max-width: 100%;
    min-width: 100%; /* Ensure the bike queue takes full width */
    margin-bottom: 20px; /* Add margin between bike queues */
  }

  .add-bike-container {
    flex-direction: column;
    align-items: stretch;
  }

  .add-bike-container input[type="text"],
  .add-bike-container button {
    width: 100%; /* Set width to 100% for both input and button */
    border-radius: 5px; /* Round all corners */
    margin-bottom: 10px; /* Add space between elements */
    border: 1px solid #ccc; /* Add border for individual elements */
  }

  .add-bike-container button {
    margin-bottom: 0; /* Remove bottom margin for the last element */
  }

  .settings-modal-content {
    width: 90%;
    max-width: 400px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .settings-modal-content {
    width: 80%;
    max-width: 600px;
  }
}

.language-selector {
  display: flex;
  gap: 10px;
  margin-top: 5px;
}

.language-selector img {
  width: 30px;
  height: 20px;
  cursor: pointer;
  transition: transform 0.3s;
}

.language-selector img:hover {
  transform: scale(1.1);
}

/* Dark Mode */
.dark-mode {
  background-color: #121212;
  color: #ffffff;
}

.dark-mode .app-container {
  background-color: transparent;
  color: #ffffff;
}

.dark-mode .bike-queue {
  background-color: #1e1e1e;
  color: #ffffff;
}

.dark-mode .settings-modal-content {
  background-color: #2c2c2c;
  color: #ffffff;
}

.dark-mode button {
  background-color: #333333;
  color: #ffffff;
}

.dark-mode button:hover {
  background-color: #555555;
}

.dark-mode input[type="text"],
.dark-mode input[type="number"],
.dark-mode select {
  background-color: #333333;
  color: #ffffff;
  border: 1px solid #555555;
}

.dark-mode .header-container {
  background-color: #1e1e1e;
}

.dark-mode .header-container p a {
  color: #bbbbbb;
}

.dark-mode .header-container p a:hover {
  color: #ffffff;
}

.dark-mode .draggable-item {
  background-color: #2c2c2c;
  border: 1px solid #444444;
}

.dark-mode .draggable-item.dragging {
  background-color: #3c3c3c;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5);
}

.dark-mode .arrow-icon {
  color: #bbbbbb;
}

.dark-mode .arrow-icon:hover {
  color: #ffffff;
}

.dark-mode .arrow-icon.disabled {
  color: #555555;
}

.dark-mode .loading-indicator .spinner {
  border-top: 16px solid #ffffff;
}

/* Ensure the root element is styled correctly */
#root {
  background-color: inherit;
  color: inherit;
}

.light-mode #root {
  background-color: #f4f4f4;
  color: #333;
}

.dark-mode #root {
  background-color: #121212;
  color: #ffffff;
}

/* Loading Indicator */
.loading-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loading-indicator .spinner {
  border-top: 16px solid #3498db;
}

.icon-container {
  display: flex;
  align-items: center;
}

.dark-mode-icon {
  cursor: pointer;
  font-size: 24px;
  padding: 10px;
  width: 24px; /* Ensure consistent width */
  height: 24px; /* Ensure consistent height */
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-bike-container {
  display: flex;
  align-items: center;
}

.add-bike-container input[type="text"] {
  width: 60%; /* Set input width to 60% */
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-right: none; /* Remove right border to merge with button */
  border-radius: 5px 0 0 5px; /* Round only the left corners */
  box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
}

.add-bike-container button {
  width: 40%; /* Set button width to 40% */
  padding: 10px 20px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-left: none; /* Remove left border to merge with input */
  border-radius: 0 5px 5px 0; /* Round only the right corners */
  cursor: pointer;
  background-color: #007bff;
  color: white;
  transition: background-color 0.3s;
  box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
}

.add-bike-container button:hover {
  background-color: #0056b3;
}

/* Responsive Design */
@media (max-width: 768px) {
  .add-bike-container {
    flex-direction: column;
    align-items: stretch;
  }

  .add-bike-container input[type="text"],
  .add-bike-container button {
    width: 100%; /* Set width to 100% for both input and button */
    border-radius: 5px; /* Round all corners */
    margin-bottom: 10px; /* Add space between elements */
    border: 1px solid #ccc; /* Add border for individual elements */
  }

  .add-bike-container button {
    margin-bottom: 0; /* Remove bottom margin for the last element */
  }
}
